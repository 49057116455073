import React from "react";
import { useQuestionContext } from "./Question";

import styles from "./Question.module.css";

/**
 * @visibleName Question.Body
 */
const QuestionBody = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useQuestionContext();

    return <div className={styles.body}>{children}</div>;
};

export default QuestionBody;
