import React from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Radio.module.css";

const Radio = (props) => {
    const activeStyles = props.disabled ? [styles.container, styles.disabled] : [styles.container];

    return (
        <label className={activeStyles.join(" ")}>
            {props.label}
            <input {...props} type="radio" />
            <span className={styles.focus}></span>
            <span className={styles.checkmark}></span>
        </label>
    );
};

export default Radio;

Radio.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Text to display next to the radio button  */
    label: PropTypes.string,
    /** Checked status of the radio button */
    checked: PropTypes.bool,
    /** Disables the radio button */
    disabled: PropTypes.bool,
    /** Gets called when the user clicks on the radio button */
    onChange: PropTypes.func,
};

Radio.defaultProps = {
    checked: false,
    disabled: false,
};
