import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Button.module.css";

const Button = ({ id, variant, onClick, disabled, block, children }) => {
    const classNames = [styles.button, styles[variant]];
    const [data, setData] = useState();

    if (disabled) {
        classNames.push(styles.disabled);
    }

    if (block) {
        classNames.push(styles.block);
    }

    useEffect(() => {
        let data;

        if (Array.isArray(children)) {
            data = [...children];

            for (let i = 0; i < data.length; i++) {
                const value = data[i];
                if (typeof value === "string") {
                    data[i] = <span key={i}>{value}</span>;
                }
            }
        } else {
            data = children;
            if (typeof children === "string") {
                data = <span>{children}</span>;
            }
        }

        setData(data);
    }, [children]);

    return (
        <button id={id} className={classNames.join(" ")} onClick={onClick} disabled={disabled}>
            {data}
        </button>
    );
};

export default Button;

Button.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** The variant for the button */
    variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "action"]),
    /** Disables the Button, preventing mouse events, even if the underlying component is an <a> element */
    disabled: PropTypes.bool,
    /** Spans the full width of the Button parent */
    block: PropTypes.bool,
    /** Gets called when the user clicks on the button */
    onClick: PropTypes.func,
};

Button.defaultProps = {
    variant: "primary",
    disabled: false,
};
