import React from "react";
import { useModalContext } from "./Modal";

import styles from "./Modal.module.css";

/**
 * @visibleName Modal.Footer
 */
const ModalFooter = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useModalContext();

    return <div className={styles.footer}>{children}</div>;
};

export default ModalFooter;
