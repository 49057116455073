import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import CardHeader from "./CardHeader";
import CardBody from "./CardBody";
import CardImage from "./CardImage";
import CardFooter from "./CardFooter";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Card.module.css";

const CardContext = createContext();

export const useCardContext = () => {
    const context = useContext(CardContext);
    if (!context) throw new Error(`Card compound components cannot be rendered outside the Card component.`);

    return context;
};

const Card = ({ id, children }) => {
    return (
        <CardContext.Provider value={{}}>
            <div id={id} className={styles.card}>{children}</div>
        </CardContext.Provider>
    );
};

export default Card;

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Image = CardImage;
Card.Footer = CardFooter;

Card.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string
};

Card.defaultProps = {};
