import React from "react";
import { useStepsContext } from "./Steps";

import styles from "./Steps.module.css";

const Step = ({ id, label, selected, isCompleted, onClick }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useStepsContext();

    return (
        <li onClick={selected ? null : onClick} className={selected ? styles.active : isCompleted ? styles.completed : ""}>
            <p>
                <span className={styles.nmbr}>{id + 1}</span>
                <span>{label}</span>
            </p>
        </li>
    );
};

export default Step;
