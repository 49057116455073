import React from "react";
import { useContentContext } from "./Content";

import styles from "./Content.module.css";

/**
 * @visibleName Content.Actions
 */
const ContentActions = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useContentContext();

    return <div className={styles.actions}>{children}</div>;
};

export default ContentActions;
