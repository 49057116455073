import React from "react";
import PropTypes from "prop-types";

import SelectGroupItemTitle from "./SelectGroupItemTitle";
import SelectGroupItemDescription from "./SelectGroupItemDescription";
import SelectGroupItemImage from "./SelectGroupItemImage";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./SelectGroupItem.module.css";

const SelectGroupItem = ({ selected, onClick, children }) => {
    const content = [];
    let image;
    children.forEach((child) => {
        if (!React.isValidElement(child)) return;

        if (child.type.name !== "SelectGroupItemImage") {
            content.push(child);
        } else {
            image = child;
        }
    });

    return (
        <div onClick={onClick} className={selected ? [styles.item, styles.selected].join(" ") : styles.item}>
            <div className={styles.box}>{content}</div>
            {image}
        </div>
    );
};

export default SelectGroupItem;

SelectGroupItem.Title = SelectGroupItemTitle;
SelectGroupItem.Description = SelectGroupItemDescription;
SelectGroupItem.Image = SelectGroupItemImage;

SelectGroupItem.propTypes = {
    /** Unique identifier of the item */
    id: PropTypes.string
};
