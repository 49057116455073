import React, { useContext, createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Steps.module.css";

const StepsContext = createContext();

export const useStepsContext = () => {
    const context = useContext(StepsContext);
    if (!context) throw new Error(`Steps compound components cannot be rendered outside the Steps component.`);

    return context;
};

const Steps = ({ id, activeStep, onChange, children }) => {
    const [active, setActive] = useState(activeStep || 0);
    const steps = [];

    useEffect(() => {
        setActive(activeStep);
    }, [activeStep])

    const trySetActive = (index) => {
        if (onChange && onChange(active, index) === false) {
            return;
        }

        setActive(index);
    };

    children.forEach((step, index) => {
        if (React.isValidElement(step) && step.type.name === "Step") {
            steps.push(
                React.cloneElement(step, {
                    key: index,
                    id: index,
                    selected: index === active,
                    isCompleted: index < active,
                    onClick: () => trySetActive(index),
                })
            );
        }
    });

    return (
        <StepsContext.Provider value={{}}>
            <div id={id} className={styles.container}>
                <ul className={styles.box}>{steps}</ul>
            </div>
            {steps[active] && steps[active].props.children}
        </StepsContext.Provider>
    );
};

export default Steps;

Steps.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** The step that is currently being displayed */
    activeStep: PropTypes.number,
    /** Function that is called when the active step is changed. Return false in this function to prevent the step from being changed. */
    onChange: PropTypes.func
};
