import React, { useContext, createContext } from "react";
import PropTypes from "prop-types";

import PanelHeader from "./PanelHeader";
import PanelBody from "./PanelBody";
import PanelFooter from "./PanelFooter";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Panel.module.css";

const PanelContext = createContext();

export const usePanelContext = () => {
    const context = useContext(PanelContext);
    if (!context) throw new Error(`Panel compound components cannot be rendered outside the Panel component.`);

    return context;
};

const Panel = (props) => {
    const activeStyles = props.noPadding ? [styles.panel, styles.noPadding] : [styles.panel];

    return (
        <PanelContext.Provider value={{}}>
            <div id={props.id} className={activeStyles.join(" ")}>
                {props.children}
            </div>
        </PanelContext.Provider>
    );
};

export default Panel;

Panel.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Optionally remove the inner padding of the panel */
    noPadding: PropTypes.bool,
};

Panel.defaultProps = {
    noPadding: false,
};

Panel.Header = PanelHeader;
Panel.Body = PanelBody;
Panel.Footer = PanelFooter;
