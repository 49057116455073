import React from "react";
import { useContentContext } from "./Content";

/**
 * @visibleName Content.Body
 */
const ContentBody = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useContentContext();

    return <React.Fragment>{children}</React.Fragment>;
};

export default ContentBody;
