import React from "react";
import { useModalContext } from "./Modal";
import PropTypes from "prop-types";

import styles from "./Modal.module.css";

/**
 * @visibleName Modal.Body
 */
const ModalBody = (props) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useModalContext();
    const activeStyles = props.useAccent ? [styles.body, styles.accent] : [styles.body];

    return (
        <div className={activeStyles.join(" ")}>
            <div className={styles.spacer}>{props.children}</div>
        </div>
    );
};

export default ModalBody;

ModalBody.propTypes = {
    /** Adds an orange color accent to the top of the modal's content */
    useAccent: PropTypes.bool,
};

ModalBody.defaultProps = {
    useAccent: false,
};
