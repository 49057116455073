import { useEffect } from "react";
import { createPortal } from "react-dom";

import "./Portal.css";

const Portal = ({ children }) => {
    const container = document.createElement("div");

    useEffect(() => {
        container.setAttribute("id", `portal-root-${Math.floor(Math.random() * 10000)}`);
        document.body.appendChild(container);

        return () => document.body.removeChild(container);
    });

    return createPortal(children, container);
};

export default Portal;
