import React, { useEffect, useState } from "react";
import { useCardContext } from "./Card";

import styles from "./Card.module.css";

/**
 * @visibleName Card.Footer
 */
const CardFooter = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useCardContext();
    const [classNames, setClassNames] = useState([styles.footer]);

    useEffect(() => {
        if (Array.isArray(children)) {
            setClassNames([styles.footer, styles.multiple]);
        } else {
            setClassNames([styles.footer, styles.single]);
        }
    }, [children]);

    return <div className={classNames.join(" ")}>{children}</div>;
};

export default CardFooter;
