import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Portal from "../Portal/Portal";
import { ChevronDown, X, XSquare } from "react-feather";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Select.module.css";

const Select = ({
    label,
    description,
    placeholder,
    options,
    selection,
    valueProperty,
    displayProperty,
    isMulti,
    isClearable,
    onChange,
    disabled,
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [coords, setCoords] = useState({});

    const [expanded, setExpanded] = useState(false);
    const expand = useRef(false);
    const controlRef = useRef();
    const optionsRef = useRef();

    const isMultiSelect = isMulti;
    const canClear = isClearable;

    const activeStyles = disabled ? [styles.select, styles.disabled] : [styles.select];

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideSelect);
        document.addEventListener("keyup", handleEscapeKey);
        window.addEventListener("blur", handleWindowEvents);
        window.addEventListener("resize", handleWindowEvents);

        return () => {
            document.removeEventListener("click", handleClickOutsideSelect);
            document.removeEventListener("keyup", handleEscapeKey);
            window.removeEventListener("blur", handleWindowEvents);
            window.removeEventListener("resize", handleWindowEvents);
        };
    }, []);

    useEffect(() => {
        if (!selection || selection.length === 0) {
            setSelectedItems([]);
            return;
        }

        if (!valueProperty) {
            setSelectedItems(Array.isArray(selection) ? selection : [selection]);
            return;
        }

        if (isMultiSelect) {
            const items = [];
            for (let i = 0; i < selection.length; i++) {
                const item = options.find((x) => x[valueProperty] === selection[i]);

                if (item) items.push(item);
            }

            setSelectedItems(items);

            if (items.length > 0) onChange && onChange(items);
        } else {
            const item = options.find((x) => x[valueProperty] === selection);

            setSelectedItems([item]);
            if (item) onChange && onChange(item);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, selection]);

    useEffect(() => {
        if (expanded) {
            const rect = controlRef.current.getBoundingClientRect();
            setCoords({
                left: rect.x,
                top: rect.y + window.scrollY + rect.height,
                width: rect.width - 2,
            });
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const handleClickOutsideSelect = (e) => {
        const path = e.path || (e.composedPath && e.composedPath())
        if (path) {
            for (let i = 0; i < path.length; i++) {
                if (path[i] === controlRef.current || path[i] === optionsRef.current) {
                    e.stopPropagation();
                    return;
                }
            }
        } else {
            // Browser isn't supplying path info
            let element = e.target;
            while (element !== null) {
                if (element === controlRef.current || element === optionsRef.current) {
                    e.stopPropagation();
                    return;
                }

                element = element.parentElement
            }
        }

        setExpanded(false);
        expand.current = false;
    };

    const handleEscapeKey = (e) => {
        if (e.key === "Escape" && expand.current) {
            setExpanded(false);
            expand.current = false;
        }
    };

    const handleWindowEvents = (e) => {
        if (expand.current) {
            expand.current = false;
            setExpanded(false);
        }
    };

    const onClickSelectBox = (e) => {
        e.stopPropagation();

        if (!expanded) {
            const rect = controlRef.current.getBoundingClientRect();
            setCoords({
                left: rect.x,
                top: rect.y + window.scrollY + rect.height,
                width: rect.width - 2,
            });
        }

        expand.current = !expanded;
        setExpanded((show) => !show);
    };

    const onClickSelectItem = (e, item) => {
        e.stopPropagation();

        if (!expanded) {
            const rect = controlRef.current.getBoundingClientRect();
            setCoords({
                left: rect.x,
                top: rect.y + window.scrollY + rect.height,
                width: rect.width - 2,
            });
        }

        if (isMultiSelect) {
            let selected = [...selectedItems];
            if (!selected.find((x) => x === item)) {
                selected.push(item);
            }

            setSelectedItems(selected);
            setExpanded(false);

            onChange && onChange(selected);
        } else {
            setSelectedItems([item]);
            setExpanded(false);

            onChange && onChange(item);
        }
    };

    const onRemoveSelectedItem = (e, item) => {
        e.stopPropagation();

        const selected = selectedItems.filter((x) => x !== item);
        setSelectedItems(selected);
        onChange && onChange(selected);
    };

    const onClearSelection = (e) => {
        e.stopPropagation();
        setSelectedItems([]);
        onChange && onChange(isMultiSelect ? [] : null);
    };

    const renderSelection = () => {
        if (!selectedItems || selectedItems.length === 0) return <span className={styles.placeholder}>{placeholder}</span>;

        if (isMultiSelect) {
            return (
                <span className={styles.multiselect_items}>
                    {selectedItems.map((item, index) => (
                        <span key={index} className={styles.multiselect_item}>
                            {renderItem(item)}
                            <XSquare onClick={(e) => onRemoveSelectedItem(e, item)} />
                        </span>
                    ))}
                </span>
            );
        } else {
            return renderItem(selectedItems[0]);
        }
    };

    const renderOptions = () => {
        let items = options;
        if (isMultiSelect) {
            items = items.filter((x) => !selectedItems.some((y) => x === y));
        }

        return (
            <ul>
                {items.map((item, i) => (
                    <li key={i} onClick={(e) => onClickSelectItem(e, item)} title={renderItem(item)}>
                        {renderItem(item)}
                    </li>
                ))}
                {items.length === 0 && <li className={styles.disabled}>No options available</li>}
            </ul>
        );
    };

    const renderItem = (item) => {
        if (!item) return;

        if (displayProperty) return item[displayProperty];
        return item;
    };

    return (
        <div className={label ? activeStyles.join(" ") : [...activeStyles, styles.withoutLabel].join(" ")}>
            {label && <label>{label}</label>}
            <div
                className={expanded ? [styles.input, styles.focused].join(" ") : styles.input}
                onClick={(e) => onClickSelectBox(e)}
                ref={controlRef}
            >
                {renderSelection()}

                <div className={isClearable ? [styles.controls, styles.clearable].join(" ") : styles.controls}>
                    {canClear && selectedItems && selectedItems.length > 0 && <X onClick={onClearSelection} />}
                    <ChevronDown />
                </div>
            </div>

            {expanded && (
                <Portal>
                    <div
                        ref={optionsRef}
                        className={styles.dropdown}
                        style={{
                            left: coords.left,
                            top: coords.top,
                            width: coords.width,
                        }}
                    >
                        {renderOptions()}
                    </div>
                </Portal>
            )}

            {description && <span className={styles.description}>{description}</span>}
        </div>
    );
};

export default Select;

Select.propTypes = {
    /** Determine if selecting multiple options is possible */
    isMulti: PropTypes.bool,
    /** Determine if it is possible to clear the active selection using a button */
    isClearable: PropTypes.bool,
    /** Label text */
    label: PropTypes.string,
    /** Description text displayed underneath the dropdown */
    description: PropTypes.string,
    /** Options array */
    options: PropTypes.array,
    /** The active selection */
    selection: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    /** The property of the object to display in the dropdown */
    displayProperty: PropTypes.string,
    /** The property of the object to use to determine selection */
    valueProperty: PropTypes.string,
    /** Gets called when the user selects an option from the dropdown */
    onChange: PropTypes.func,
};

Select.defaultProps = {
    isMulti: false,
    isClearable: false,
};
