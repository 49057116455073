import React from "react";
import { useCardContext } from "./Card";
import PropTypes from "prop-types";

import styles from "./Card.module.css";

/**
 * @visibleName Card.Image
 */
const CardImage = ({ src }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useCardContext();

    return src && <div className={styles.image} style={{ backgroundImage: `url(${src})` }}></div>;
};

export default CardImage;

CardImage.propTypes = {
    /** Path to the image to display in the card */
    src: PropTypes.string,
};

CardImage.defaultProps = {
    src: null,
};
