import React from "react";
import PropTypes from "prop-types";
import { X } from "react-feather";
import { useModalContext } from "./Modal";

import styles from "./Modal.module.css";

/**
 * @visibleName Modal.Header
 */
const ModalHeader = (props) => {
    const { onHide } = useModalContext();

    return (
        <div className={styles.header}>
            {props.children}
            {props.closeButton && (
                <div className={styles.close} onClick={onHide}>
                    <X />
                </div>
            )}
        </div>
    );
};

export default ModalHeader;

ModalHeader.propTypes = {
    /** Specify whether the component should contain a close button */
    closeButton: PropTypes.bool,
};

ModalHeader.defaultProps = {
    closeButton: true,
};
