import React from "react";
import { useCardContext } from "./Card";

import styles from "./Card.module.css";

/**
 * @visibleName Card.Header
 */
const CardHeader = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useCardContext();

    return <div className={styles.header}>{children}</div>;
};

export default CardHeader;
