import React from "react";
import { useQuestionContext } from "./Question";

import styles from "./Question.module.css";

/**
 * @visibleName Question.Title
 */
const QuestionTitle = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useQuestionContext();

    return <p className={styles.title}>{children}</p>;
};

export default QuestionTitle;
