import React from "react";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Panel.module.css";

const PanelGroup = (props) => {
    return <div className={styles.group}>{props.children}</div>;
};

export default PanelGroup;
