import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import BoxTitle from "./BoxTitle";
import BoxBody from "./BoxBody";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Box.module.css";

const BoxContext = createContext();

export const useBoxContext = () => {
    const context = useContext(BoxContext);
    if (!context) throw new Error(`Box compound components cannot be rendered outside the Box component.`);

    return context;
};

const Box = (props) => {
    const activeStyles = props.useAccent ? [styles.box, styles.accent] : [styles.box];

    return (
        <BoxContext.Provider value={{}}>
            <div id={props.id} className={activeStyles.join(" ")}>{props.children}</div>
        </BoxContext.Provider>
    );
};

export default Box;

Box.Title = BoxTitle;
Box.Body = BoxBody;

Box.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Adds an orange color accent to the top of the box */
    useAccent: PropTypes.bool,
};

Box.defaultProps = {
    useAccent: false,
};
