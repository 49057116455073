import React, { useContext, createContext } from "react";
import PropTypes from "prop-types";

import QuestionTitle from "./QuestionTitle";
import QuestionBody from "./QuestionBody";
import QuestionInfo from "./QuestionInfo";
import QuestionImage from "./QuestionImage";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Question.module.css";

const QuestionContext = createContext();

export const useQuestionContext = () => {
    const context = useContext(QuestionContext);
    if (!context) throw new Error(`Question compound components cannot be rendered outside the Question component.`);

    return context;
};

const Question = (props) => {
    let content = [];
    let image;
    props.children.forEach((child) => {
        if (React.isValidElement(child)) {
            if (child.type.name !== "QuestionImage") {
                content.push(child);
            } else {
                image = child;
            }
        }
    });
    return (
        <QuestionContext.Provider value={{}}>
            <div id={props.id} className={styles.question}>
                <div className={styles.content}>{content}</div>
                {image}
            </div>
        </QuestionContext.Provider>
    );
};

export default Question;

Question.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string
};

Question.Title = QuestionTitle;
Question.Body = QuestionBody;
Question.Info = QuestionInfo;
Question.Image = QuestionImage;
