import React from "react";
import { useBoxContext } from "./Box";

import styles from "./Box.module.css";

/**
 * @visibleName Box.Body
 */
const BoxBody = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useBoxContext();

    return <div className={styles.body}>{children}</div>;
};

export default BoxBody;
