import React from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Subheader.module.css";

const Subheader = (props) => {
    const color = props.color === "" ? "orange" : props.color;

    return (
        <div id={props.id} className={styles.subheader}>
            <div className={styles.line}></div>
            <div className={[styles.content, styles[color]].join(" ")}>{props.children}</div>
        </div>
    );
};

export default Subheader;

Subheader.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** The color of the box */
    color: PropTypes.oneOf(["yellow", "green", "blue", "purple", "red", "orange"]),
};

Subheader.defaultProps = {
    color: "orange",
};
