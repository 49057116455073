import React from "react";
import { useBoxContext } from "./Box";

import styles from "./Box.module.css";

/**
 * @visibleName Box.Title
 */
const BoxTitle = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useBoxContext();

    return <h3 className={styles.title}>{children}</h3>;
};

export default BoxTitle;
