import React from "react";
import { usePanelContext } from "./Panel";

import styles from "./Panel.module.css";

/**
 * @visibleName Panel.Header
 */
const PanelHeader = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = usePanelContext();

    return <div className={styles.header}>{children}</div>;
};

export default PanelHeader;
