import React from "react";
import { useCardContext } from "./Card";

import styles from "./Card.module.css";

/**
 * @visibleName Card.Body
 */
const CardBody = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useCardContext();

    return <div className={styles.body}>{children}</div>;
};

export default CardBody;
