import React from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Table.module.css";

const Table = (props) => {
    return (
        <table id={props.id} className={styles.table}>
            {props.children}
        </table>
    );
};

export default Table;

Table.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
};
