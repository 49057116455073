import React from "react";
import { useSelectGroupContext } from "./SelectGroup";

/**
 * @visibleName SelectGroupItem.Title
 */
const SelectGroupItemTitle = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useSelectGroupContext();

    return <h3>{children}</h3>;
};

export default SelectGroupItemTitle;
