import React, { useContext, createContext } from "react";
import PropTypes from "prop-types";

import ContentHeader from "./ContentHeader";
import ContentActions from "./ContentActions";
import ContentInfo from "./ContentInfo";
import ContentBody from "./ContentBody";
import ContentFooter from "./ContentFooter";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Content.module.css";

const ContentContext = createContext();

export const useContentContext = () => {
    const context = useContext(ContentContext);
    if (!context) throw new Error(`Content compound components cannot be rendered outside the Content component.`);

    return context;
};

const Content = (props) => {
    return (
        <ContentContext.Provider value={{}}>
            <div id={props.id} className={styles.content}>{props.children}</div>
        </ContentContext.Provider>
    );
};

export default Content;

Content.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string
};

Content.Header = ContentHeader;
Content.Actions = ContentActions;
Content.Info = ContentInfo;
Content.Body = ContentBody;
Content.Footer = ContentFooter;
