import React from "react";
import { usePanelContext } from "./Panel";

import styles from "./Panel.module.css";

/**
 * @visibleName Panel.Footer
 */
const PanelFooter = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = usePanelContext();

    return <div className={styles.footer}>{children}</div>;
};

export default PanelFooter;
