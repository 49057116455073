import React from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./InputTextArea.module.css";

/**
 * @visibleName Input.TextArea
 */
const InputTextArea = (props) => {
    return (
        <div className={styles.main}>
            <textarea {...props} className={styles.textarea} type="text" autoComplete="off"></textarea>
            {props.required && <span className={styles.required}>Required field</span>}
        </div>
    );
};

export default InputTextArea;

InputTextArea.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Value text  */
    value: PropTypes.string,
    /** Gets called when the value is changed */
    onChange: PropTypes.func,
    /** Placeholder text  */
    placeholder: PropTypes.string,
    /** Is the input required */
    required: PropTypes.bool,
};

InputTextArea.defaultProps = {
    required: false,
};
