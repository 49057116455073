import React from "react";
import { usePanelContext } from "./Panel";

import styles from "./Panel.module.css";

/**
 * @visibleName Panel.Body
 */
const PanelBody = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = usePanelContext();

    return <div className={styles.body}>{children}</div>;
};

export default PanelBody;
