import React from "react";
import { useSelectGroupContext } from "./SelectGroup";

/**
 * @visibleName SelectGroupItem.Image
 */
const SelectGroupItemImage = ({ src }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useSelectGroupContext();

    return src && <img src={src} alt="" />;
};

export default SelectGroupItemImage;
