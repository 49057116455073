import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Plus, Minus } from "react-feather";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./InputNumeric.module.css";

/**
 * @visibleName Input.Numeric
 */
const InputNumeric = (props) => {
    const input = useRef();
    const [timer, setTimer] = useState();

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;

    useEffect(() => {
        if (!props.value) return;

        if (Number(props.value) > props.max) input.current.value = props.max;
        else if (Number(props.value) < props.min) input.current.value = props.min;
        else input.current.value = Number(props.value);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const increment = () => {
        if (props.max <= input.current.value) return;
        input.current.value = Number(input.current.value);

        nativeInputValueSetter.call(input.current, Number(input.current.value) + props.step);
        const e = new Event("change", { bubbles: true });
        input.current.dispatchEvent(e);
    };

    const decrement = () => {
        if (props.min >= input.current.value) return;
        input.current.value = Number(input.current.value);

        nativeInputValueSetter.call(input.current, Number(input.current.value) - props.step);
        const e = new Event("change", { bubbles: true });
        input.current.dispatchEvent(e);
    };

    const onInputChanged = (e) => {
        input.current.value = Number(e.target.value);
        props.onChange && props.onChange(e);
    };

    const onValidateInput = (e) => {
        if (Number(e.target.value) > props.max) input.current.value = props.max;
        else if (Number(e.target.value) < props.min) input.current.value = props.min;
        else input.current.value = Number(e.target.value);

        props.onChange && props.onChange(e);
    };

    const startAction = (action) => {
        clearInterval(timer);
        action();
        const interval = setInterval(action, 250);
        setTimer(interval);
    };

    const stopAction = () => {
        clearInterval(timer);
    };

    return (
        <div className={styles.main}>
            <div className={styles.numeric}>
                <input
                    {...props}
                    ref={input}
                    type="number"
                    onChange={onInputChanged}
                    onBlur={onValidateInput}
                    autoComplete="off"
                />
                <div className={styles.controls}>
                    <button onMouseDown={() => startAction(increment)} onMouseUp={stopAction} onMouseLeave={stopAction} tabIndex={-1}>
                        <Plus />
                    </button>
                    <button onMouseDown={() => startAction(decrement)} onMouseUp={stopAction} onMouseLeave={stopAction} tabIndex={-1}>
                        <Minus />
                    </button>
                </div>
                {props.legend && <span className={styles.legend}>{props.legend}</span>}
            </div>
            {props.required && <span className={styles.required}>Required field</span>}
        </div>
    );
};

export default InputNumeric;

InputNumeric.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Minimum allowed value */
    min: PropTypes.number,
    /** Maximum allowed value */
    max: PropTypes.number,
    /** Increment/decrement buttons step size */
    step: PropTypes.number,
    /** Displayed value  */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Gets called when the value is changed */
    onChange: PropTypes.func,
    /** Is the input required */
    required: PropTypes.bool,
};

InputNumeric.defaultProps = {
    min: 0,
    max: 100,
    required: false,
    step: 1
};
