import React from "react";
import { Info } from "react-feather";
import { useQuestionContext } from "./Question";

import styles from "./Question.module.css";

/**
 * @visibleName Question.Info
 */
const QuestionInfo = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useQuestionContext();

    return (
        <div className={styles.info}>
            <Info className={styles.icon} />
            <p>{children}</p>
        </div>
    );
};

export default QuestionInfo;
