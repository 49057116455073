import React, { useRef } from "react";

import styles from "./Overlay.module.css";

const Overlay = (props) => {
    const overlay = useRef();

    const handleClick = (e) => {
        if (e.target !== overlay.current) return;
        props.onClick && props.onClick();
    };

    return (
        <div className={styles.overlay} ref={overlay} onClick={handleClick}>
            {props.children}
        </div>
    );
};

export default Overlay;
