import InputNumeric from "./InputNumeric";
import InputPassword from "./InputPassword";
import InputText from "./InputText";
import InputTextArea from "./InputTextArea";

import "../../styles/Global.css";
import "../../styles/Typography.css";

const Input = _ => {
    return null;
};

export default Input;

Input.Numeric = InputNumeric;
Input.Password = InputPassword;
Input.Text = InputText
Input.TextArea = InputTextArea;