import React from "react";
import { useQuestionContext } from "./Question";
import PropTypes from "prop-types";

import styles from "./Question.module.css";

/**
 * @visibleName Question.Image
 */
const QuestionImage = (props) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useQuestionContext();

    return props.src && <div className={styles.image} style={{ backgroundImage: "url(" + props.src + ")" }}></div>;
};

export default QuestionImage;

QuestionImage.propTypes = {
    /** Path to the image to display in the question box */
    src: PropTypes.string,
};

QuestionImage.defaultProps = {
    src: null,
};
