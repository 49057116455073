import React, { useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import Portal from "../Portal";
import Overlay from "../Overlay";

import ModalHeader from "./ModalHeader";
import ModalTitle from "./ModalTitle";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

import "../../styles/Global.css";
import "../../styles/Typography.css";
import styles from "./Modal.module.css";

const ModalContext = createContext();

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context) throw new Error(`Modal compound components cannot be rendered outside the Modal component.`);

    return context;
};

const Modal = (props) => {
    useEffect(() => {
        document.body.classList.toggle("noscroll");
        return () => document.body.classList.toggle("noscroll");
    }, []);

    const state = {
        onHide: props.onHide,
    };

    return (
        <ModalContext.Provider value={state}>
            <Portal>
                <Overlay onClick={props.onHide}>
                    <div id={props.id} className={[styles.modal, styles[props.size]].join(" ")}>{props.children}</div>
                </Overlay>
            </Portal>
        </ModalContext.Provider>
    );
};

export default Modal;

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

Modal.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** The size of the modal */
    size: PropTypes.oneOf(["small", "normal", "large"]),
    /** A callback fired when the header closeButton or non-static backdrop is clicked. Required if either are specified. */
    onHide: PropTypes.func,
};

Modal.defaultProps = {
    size: "normal",
};
