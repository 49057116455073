import React from "react";
import { useModalContext } from "./Modal";

/**
 * @visibleName Modal.Title
 */
const ModalTitle = (props) => {
    // eslint-disable-next-line no-unused-vars
    const _ = useModalContext();

    return <h2>{props.children}</h2>;
};

export default ModalTitle;
