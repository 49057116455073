import React, { useContext, createContext, useState } from "react";
import PropTypes from "prop-types";

import "../../styles/Global.css";
import "../../styles/Typography.css";

const SelectGroupContext = createContext();

export const useSelectGroupContext = () => {
    const context = useContext(SelectGroupContext);
    if (!context) throw new Error(`SelectGroup compound components cannot be rendered outside the SelectGroup component.`);

    return context;
};

const SelectGroup = ({ id, children, onChange, initialSelection }) => {
    const [active, setActive] = useState(initialSelection);

    const onItemClicked = (index) => {
        setActive(index);
        onChange && onChange(index);
    };

    return (
        <SelectGroupContext.Provider value={{}}>
            <div id={id}>
                {children.map((item, index) => {
                    if (!React.isValidElement(item) || item.type.name !== "SelectGroupItem") return null;

                    return (
                        <React.Fragment key={index}>
                            {React.cloneElement(item, {
                                selected: active === Number(item.props.id),
                                onClick: () => onItemClicked(Number(item.props.id)),
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
        </SelectGroupContext.Provider>
    );
};

export default SelectGroup;

SelectGroup.propTypes = {
    /** Adds the value as id to the element */
    id: PropTypes.string,
    /** Id of the selected value */
    initialSelection: PropTypes.number,
    /** Gets called when the user clicks on one of the SelectGroupItem components */
    onChange: PropTypes.func,
};
